#button-group button {
  background-color: rgba(255, 255, 255, 0);
  color: #216ba5;
  width: 100%;
}

#button-group button:hover {
  background-color: rgb(255, 255, 255, 0);
  color: #3b83bd;
}

.icon {
  vertical-align: middle;
}

.bReporte {
  color: #40403f;
}

.amarillo {
  color: #f28d29;
  vertical-align: middle;
  margin-left: 8em;
}

.rojoCostos {
  color: #ee2737;
  vertical-align: middle;
}

.amarilloVentas {
  color: #f28d29;
  vertical-align: middle;
}

.morado {
  color: #855fa5;
  vertical-align: middle;
}

.rojo {
  color: #ee2737;
  vertical-align: middle;
}

.cyan {
  color: #58aeb7;
  vertical-align: middle;
}

.cyanVentas {
  color: #58aeb7;
  vertical-align: middle;
  margin-left: 5em;
}

.containerProductos {
  width: 93%;
  align-content: center;
  align-items: center;
  vertical-align: middle
}

.containerGraficas {
  width: 86%;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  margin-left: 6%;
}

.card-custome-productos {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0.5rem;
  margin-left: 67px;
}

.card-header-productos {
  color: white;
  background-color: #855fa5d1;
  border-radius: 1.25rem;
  padding-bottom: 0%;
}

.bordeProductos {
  border-color: #855fa5;
  border-style: solid;
  border-width: 2px;
}

.child {
  position: absolute;
  top: 50%;
  height: 70px;
  /* if text is one-line, line-height equal to height set text to the middle */
  line-height: 70px;
  /* margin-top is negative 1/2 of height */
  margin-top: -35px;
  /* decoration */
  width: 200px;
  text-align: center;
  background-color: #dfd;
}

.cardCentrado {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  vertical-align: middle;
  position: absolute;
  top: 70%;
  left: 10%;
}

.centrarTexto {
  text-align: center;
  padding: 15px;
}

.calendarioProductos {
  background-color: #855fa5d1;
  /*background-color: #58aeb72b;*/
  border-style: none;
  color: #fff;
  text-align: center;
  width: 100%;
}

#button-group button{
    background-color:rgba(255, 255, 255, 0);
    color: #216ba5;
    width: 100%; 
  }
  #button-group button:hover{
    background-color: rgb(255, 255, 255, 0);
    color: #3b83bd;
  }

  .icon {
    vertical-align: middle;
  }

  .bReporte {
    color: #40403f;
  }

  .morado{
    color: #855fa5;
    vertical-align:middle;
  }

  .card-custome {
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    background-color: rgba(255, 255, 255, 0) !important;
    border-radius: 0.5rem;
    margin-left: 67px;
  }

  .card-header-productos {
    color: white;
    background-color: #855fa5d1;
    border-radius: 1.25rem;  
  }

  .bordeProductos{
      border-color: #855fa5;
      border-style: solid;
      border-width: 2px;
  }

  .child { 
    position: absolute;
    top: 50%;
    height: 70px;
    /* if text is one-line, line-height equal to height set text to the middle */
    line-height: 70px;
    /* margin-top is negative 1/2 of height */
    margin-top: -35px;

    /* decoration */
    width: 200px;
    text-align: center;
    background-color: #dfd;
  }

  .centrarTexto {
    text-align: center;
    padding: 15px;
  }

  .calendarioProductos {
    background-color: #855fa5d1;
    /*background-color: #58aeb72b;*/
    border-style: none;
    color: #fff;
    text-align: center;
    width: 100%;
  }

  #calendario::placeholder {
    color:#fff;
  }
  #calendario::-webkit-input-placeholder {
    color: #fff;
  }
  #calendario::-moz-placeholder {
    color: #fff;
  }
  #calendario:-ms-input-placeholder {
    color: #fff;
  }
  #calendario:-moz-placeholder {
    color: #fff;
  }

  .legendProductos {
    font-size: 18px;
    color: #855fa5d1;
    text-align: left !important;
    font-weight: bold;
  }
  
  .fieldsetProductos {
    border: 1px solid rgb(226, 223, 223) !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding-left: 10px!important;
  }
