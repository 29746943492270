.img-back-modal {
    padding: 5px;
}

.modal-header {
    border-bottom: 0;
}

.modal-table{
    border-style: dashed hidden dashed hidden;
}

.left-cell{
    text-align: right;
    padding-left: 0;
}