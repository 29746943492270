.nav-back-color {
    background-color: white !important;
}

.img-back {
    padding: 5px;
}

.font-login-name{    
    font-size: 16px;
}

.navbar-light .navbar-text {
    color: rgba(238, 39, 55, 0.67);
}

.material-icons.md-dark {
    color: rgba(238, 39, 55, 0.67);
}

.material-icons.md-24 {
    font-size: 32px;
}