.accionEstatus {
    background-color: #3eb9abb0;
    border: none;
    color: white;
    padding: 2px 7px 2px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    font-size: 14px;
    margin: 2px 2px;
    cursor: pointer;
  }

  .accionVolver {
    background-color: #bc322ccf;;
    border: none;
    color: white;
    padding: 2px 7px 2px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    font-size: 14px;
    margin: 2px 2px;
    cursor: pointer;
  }
  .header-delete {
    color: white;
    background-color: #58aeb7c2;
  }

  .iconAlign {
    vertical-align: middle;
}