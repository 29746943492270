.btn-send-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(88, 174, 183, 0.75);
}

.btn-send-back:hover {
    background-color: rgb(255, 255, 255, 0);
    color: rgba(88, 174, 183, 0.75);
}

.btn-done-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(132, 192, 108, 0.75);
}

.btn-done-back:hover {
    background-color: rgb(255, 255, 255, 0);
    color: rgba(132, 192, 108, 0.75);
}

.btn-fail-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(238, 39, 55, 0.75);
}

.btn-fail-back:hover {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(238, 39, 55, 0.75);
}

.card-custome-estatus {
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    margin-left: 67px;
    margin-right: 1rem;
    background-color: rgba(255, 255, 255, 0) !important;
    border-radius: 0.5rem;
}

.card-header-reporte {
    color: white;
    background-color: rgba(238, 39, 55, 0.75);
    font-size: 1.142rem;
    border-radius: 1.25rem;
    padding-bottom: 0%;
}

.containerEstatus {
    width: 93%;
    align-content: center;
    align-items: center;
    vertical-align: middle
}

.fieldsetEstatus {
    border: 1px solid rgb(226, 223, 223) !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding-left: 10px!important;
}

.img-back {
    padding: 5px;
}

.nav-back-color {
    background-color: white !important;
    color: white;
}

.nav-text {
    padding-top: 2rem;
    padding-bottom: 0;
    color: #494a4c;
    font-weight: 100;
}

.nav-text:hover {
    color: #494a4c;
}

.row-estatus {
    margin-top: 2.5rem;
}

/*Inicio diseño del paginador */

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #bbbab9;
    border-color: #bbbab9;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 0.95;
    color: #bbbab9;
    background-color: #fff0;
    border: 1px solid #fff0;
  }
  
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0.2rem rgba(244, 173, 101, 0)
  }
  
  .page-link:hover {
    z-index: 2;
    color: #000000;
    text-decoration: none;
    background-color: #e9ecef00;
    border-color: #dee2e600;
  }
  /*Fin diseño del paginador */