.sidenav-back{
    background: #ee2737;
    top: 96px;
    position: fixed;
}

.toggle-back{
    height: 60px;
}

.item-tam{
    height: 60px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    color: white;
}

.sidenav---sidenav---_2tBP {
    box-sizing: border-box;
    line-height: 20px;
    height: 1100px;    
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 265px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
    color: #ffffff;
    padding-top: 12px;
}