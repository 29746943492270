.btn-add-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(88, 174, 183, 0.75);
}

.btn-add-back:hover {
    background-color: rgb(255, 255, 255, 0);
    color: rgba(88, 174, 183, 0.75);
}

.btn-edit-local-back {
    background-color: rgb(255, 255, 255, 0);
    color: rgba(133, 95, 165, 0.85)
}

.btn-edit-cuenta-local-back {
    background-color: rgb(255, 255, 255, 0);
    color: rgba(242, 141, 41, 0.85)
}

.btn-erase-local-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(238, 39, 55, 0.85);
}

.btn-matriz-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(132, 192, 108, 1);
}

.btn-matriz-back:hover {
    background-color: rgb(255, 255, 255, 0);
    color: rgba(132, 192, 108, 1);
}

.btn-nomatriz-back {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(238, 39, 55, 0.45);
}

.btn-nomatriz-back:hover {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(238, 39, 55, 0.45);
}

.card-custom-listado {
    margin-left: 5rem;
    margin-right: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    background-color: rgb(255, 255, 255, 0);
    border-radius: 0.5rem;
}

.card-header-listado-locales {
    color: white;
    background-color: #58aeb7c2;
    padding-bottom: 0%;
}

.containerLocales {
    width: 93%;
    align-content: center;
    align-items: center;
    vertical-align: middle
}

/*Inicio diseño del paginador */

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #bbbab9;
    border-color: #bbbab9;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 0.95;
    color: #bbbab9;
    background-color: #fff0;
    border: 1px solid #fff0;
  }
  
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0.2rem rgba(244, 173, 101, 0)
  }
  
  .page-link:hover {
    z-index: 2;
    color: #000000;
    text-decoration: none;
    background-color: #e9ecef00;
    border-color: #dee2e600;
  }
  
  /*Fin diseño del paginador */