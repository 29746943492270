.accionGuardar {
    background-color: #4caaaf;
    border: none;
    color: white;
    margin: 20px 20px 20px 20px;
    padding: 13px 15px 13px 10px;
    display: inline-flex;
}

.accionCancelar {
    background-color: #bc322ccf;
    border: none;
    color: white;
    margin: 20px 20px 20px 20px;
    padding: 13px 15px 13px 10px;
    display: inline-flex;
}

.iconAlign {
    vertical-align: middle;
}

.containerFormLocales {
    width: 93%;
    align-content: center;
    align-items: center;
    vertical-align: middle;
}

.card-header-form {
    color: white;
    background-color: #58aeb7c2;
    border-radius: 1.25rem;
}

.bordeForm {
    border-color: #58aeb7;
    border-style: solid;
    border-width: 2px;
}

.formLocales {
    text-align: left !important;
}

.modal-title {
    color: #58aeb7;
}

.modal-backdrop {    
    background-color: #6c6c6c
}

legend {
    font-size: 18px;
    color: #58aeb7;
    text-align: left !important;
    font-weight: bold;
}

fieldset {
    border: 1px solid rgb(226, 223, 223) !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding-left: 10px!important;
}