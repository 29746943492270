.backgroud-one {
    background-color:rgba(238,39,55,0) !important;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    border-style: solid;
    border-width: 2px 0px 2px 2px;
    border-color: rgba(230, 49, 41,0.75);
}

.backgroud-two {
    padding: 50px 35px 50px 35px;
    background-color: rgba(230, 49, 41, 0.75) !important;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    color: white;
    border-style: solid;
    border-width: 2px 2px 2px 0px;
    border-color: rgba(230, 49, 41,0.75);
}

.btn-login {
    background-color: rgba(230, 49, 41, 0.8);
    border-color: rgba(0, 0, 0, 0);
    color: white;
}

.btn-login:hover {
    background-color: rgba(230, 49, 41, 0.8);
    border-color: rgba(0, 0, 0, 0.075);
    color: white;
}

.col-back {
    margin-top: 4%;
}

.container-back {
    margin: 0 auto;
    width: 500px;
}

.div-img {
    display: block;
    margin-top: 7rem;
    width: 100%;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transition: all 300ms ease-in-out;
}

.div-img:hover {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
}

.row-background {
    margin-top: 30%;
    justify-content: center;
}

.row-config {
    margin-bottom: 1rem;
}