#button-group button {
  background-color: rgba(255, 255, 255, 0);
  color: #58aeb7ab;
}

#button-group button:hover {
  background-color: rgb(255, 255, 255, 0);
  color: #58aeb7;
}

.amarillo {
  color: #f28d29;
  vertical-align: middle;
  margin-left: 8em;
}

.rojoCostos {
  color: #ee2737;
  vertical-align: middle;
}

.amarilloVentas {
  color: #f28d29;
  vertical-align: middle;
}

.morado {
  color: #855fa5;
  vertical-align: middle;
}

.rojo {
  color: #ee2737;
  vertical-align: middle;
}

.cyan {
  color: #58aeb7;
  vertical-align: middle;
}

.cyanVentas {
  color: #58aeb7;
  vertical-align: middle;
  margin-left: 5em;
}

.containerVentas {
  width: 93%;
  align-content: center;
  align-items: center;
  vertical-align: middle
}

.containerGraficas {
  width: 86%;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  margin-left: 6%;
}

.card-custome {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0.5rem;
  margin-left: 67px;
}

.card-header-ventas {
  color: white;
  background-color: #58aeb7c2;
  border-radius: 1.25rem;
  padding-bottom: 0%;
}

.bordeVenta {
  border-color: #58aeb7;
  border-style: solid;
  border-width: 2px;
}

.card-header-costos {
  color: white;
  background-color: #855fa5d1;
  border-radius: 1.25rem;
  padding-bottom: 0%;
}

.bordeCostos {
  border-color: #855fa5;
  border-style: solid;
  border-width: 2px;
}

.bordeGraficaVentaTotal {
  border-color: #ee2737;
  /*#f28d29;*/
  border-style: solid;
  border-width: 2px;
}

.bordeGraficaVentaBruta {
  border-color: #f28d29;
  /*#f28d29;*/
  border-style: solid;
  border-width: 2px;
}

.card-header-g-ventaTotal {
  color: white;
  background-color: #ee2737cc;
  /*#f28d29b5;*/
  border-radius: 1.25rem;
  padding-bottom: 0%;
}

.card-header-g-ventaBruta {
  color: white;
  background-color: #f28d29b5;
  /*#f28d29b5;*/
  border-radius: 1.25rem;
  padding-bottom: 0%;
}

.child {
  position: absolute;
  top: 50%;
  height: 70px;
  /* if text is one-line, line-height equal to height set text to the middle */
  line-height: 70px;
  /* margin-top is negative 1/2 of height */
  margin-top: -35px;
  /* decoration */
  width: 200px;
  text-align: center;
  background-color: #dfd;
}

.cardCentrado {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  vertical-align: middle;
  position: absolute;
  top: 70%;
  left: 10%;
}

.centrar-texto-ventas {
  text-align: center;
  padding: 15px;
}

.calendarioVentas {
  background-color: #58aeb7c2;
  /*background-color: #58aeb72b;*/
  border-style: none;
  color: #fff;
  text-align: center;
  width: 100%;
}

.piechart-center{
  justify-content: center;
}

  #calendario::placeholder {
    color:#fff;
  }
  #calendario::-webkit-input-placeholder {
    color: #fff;
  }
  #calendario::-moz-placeholder {
    color: #fff;
  }
  #calendario:-ms-input-placeholder {
    color: #fff;
  }
  #calendario:-moz-placeholder {
    color: #fff;
  }
