.btn-tickets{
  color: #f4a04d;
  background-color: #f8f9fa00;
  border-color: #f8f9fa00;
}

.btn-tickets:hover {
  color: #f4ac65;
  background-color: #e2e6ea00;
  border-color: #dae0e500;
}

.calendario-tickets {
  background-color: #f28d2962;
  /*background-color: #58aeb72b;*/
  border-style: none;
  color: white;
  text-align: center;
  width: 100%;
}

.card-custom-tickets {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  margin-left: 67px;
  margin-right: 1rem;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0.5rem;
}

.card-header-tickets {
  color: white;
  background-color: #f28d29b5;
  border-radius: 1.25rem;
  padding-bottom: 0%;
}

.card-centrado-tickets {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  vertical-align: middle;
  position: absolute;
  top: 70%;
  left: 10%;
}

.centrar-texto-tickets {
  text-align: center;
  padding: 15px;
}

.containerTickets {
  width: 93%;
  align-content: center;
  align-items: center;
  vertical-align: middle
}

.fieldsetTickets {
  border: 1px solid rgb(226, 223, 223) !important;
  margin: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding-left: 10px!important;
}

.form-row>.col, .form-row>[class*="col-"] {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/*Inicio diseño del paginador */

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #bbbab9;
  border-color: #bbbab9;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 0.95;
  color: #bbbab9;
  background-color: #fff0;
  border: 1px solid #fff0;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0.2rem rgba(244, 173, 101, 0)
}

.page-link:hover {
  z-index: 2;
  color: #000000;
  text-decoration: none;
  background-color: #e9ecef00;
  border-color: #dee2e600;
}
/*Fin diseño del paginador */

#calendario::placeholder {
  color: #fff;
}

#calendario::-webkit-input-placeholder {
  color: #fff;
}

#calendario::-moz-placeholder {
  color: #fff;
}

#calendario:-ms-input-placeholder {
  color: #fff;
}

#calendario:-moz-placeholder {
  color: #fff;
}